/* .grid * { background-color: rgba(132, 0, 255, 0.208);} */

.grid {
    display: flex;
    flex-wrap: wrap;
}

.grid.right {
    justify-content: flex-end;
}
.grid.bottom {
    align-items: flex-end;
}

.tile,
.spacer {
    display: inline-block;
    /* position: relative; */
    width: 25%;
    height: 25%;
    aspect-ratio: 1/1;
}

.tile.double,
.spacer.double {
    width: 50%;
    height: 25%;
    column-span: 2;
    grid-column: auto / span 2;
    aspect-ratio: 2/1;
}

@media (max-width:799px) {
    .hideMobile {
        display: none;
    }
}

@media (min-width:800px) {
    .tile,
    .spacer {
        width: 25%;
        height: 25%;
    }

    .tile.double,
    .spacer.double {
        width: 50%;
        height: 25%;
    }

}