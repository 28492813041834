:root {

    /* master brand */

    --brand-primary: #058FFF;
    --brand-secondary: #004891;
    --brand-accent: #FE0065;


    /* color */

    --primary: #0B40FF;
    --secondary: #002b87;
    --accent: #E920FF;

    --bg-global: rgb(255, 255, 255);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #F3F6FB;
    --light: #E6EAF3;
    --medium: #003384;
    --dark: #00173e;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);


    /* spacing */

    --container-max-width: 200rem;
    --container-adjacent-spacing-factor: 0.5;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* type */

    --default-font-family: 'Space Grotesk', sans-serif;
    --default-font-weight: 200;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 0;

    --gap-divider-color: currentColor;

    /* header */

    --header-height: 6.875rem;
    --header-height-mobile: 9rem;


    /* OFFSET UI ---------------------------- */

    --layout-offset: var(--space-lg);



}
