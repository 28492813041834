.hosts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}
.partnerLogo {
    display: block;
    max-width: 10rem;
    max-height: 9rem;
}
.contacts {
    display: grid;
    gap: var(--space-sm);
}
.contactsPhoto {
    width: 10rem;
}

.graphic {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 8rem;
}

@media(max-width:799px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--space-md);
    }
    .graphic {
        width: 15vw;
        height: 20rem;
        overflow: hidden;
    }
        .ringOne {
            position: absolute;
            top: 0;
            left: 0;
            width: 20rem;
            height: 20rem;
            animation: rotate 40s infinite linear;
        }
}

@media(min-width:800px) {
    .container {
        display: grid;
        grid-template-columns: repeat(3, auto);
    }
    .section {
        padding: 0 var(--space-sm);
        border-left: 1px solid var(--secondary);
    }
    .contacts {
        grid-template-columns: auto 1fr;
    }
    .contactsPhoto {
        width: 10vw;
    }
    .graphic {
        width: 25vw;
        height: 8rem;
        overflow: hidden;
    }
        .ringOne {
            position: absolute;
            top: 0;
            right: -40%;
            animation: rotate 40s infinite linear;
        }
}


@media print {
    .container {display: none}
}


@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}