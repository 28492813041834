.arrowButton {
    cursor: pointer;
    display: inline-grid;
    grid-template-columns: 3rem 1fr;
    
    align-items: center;
    position: relative;
    text-decoration: none;
    
    height: 3rem;

    font-weight: 600;
    white-space: nowrap;
    line-height: 1;

    transition: all 550ms cubic-bezier(0.65, 0, 0.076, 1);
}



.labelContainer {
    grid-area: 1/2 / 2/3;
    z-index: 1;
    padding: 1rem 2.25rem 1rem 1.25rem;
}

.arrowContainer {
    grid-area: 1/1 / 2/2;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.box {
    display: block;
    z-index: 0;
    position: absolute;
    inset: 0;
    height: 3rem;
    width: 3rem;
    background-color: transparent;
    border: 1px solid currentColor;

    transition: all 550ms cubic-bezier(0.65, 0, 0.076, 1);
}
.arrowButton:hover .box {
    width: 100%;
}
.arrowButton.solid .box {
    background-color: var(--accent);
}



.arrow {
    width: 2rem;
    height: 1rem;
    stroke: currentColor;
    transition: all 550ms cubic-bezier(0.65, 0, 0.076, 1);
}
.arrowButton:hover .arrow {
    transform: translateX(1rem);
}




.arrowButton.lg {
    grid-template-columns: 4rem 1fr;
    height: 4rem;
    font-size: 1.6rem;
}
.arrowButton.lg::before {
    width: 4rem;
}
.arrowButton.lg:hover::before {
    width: 100%;
}