.header,
.shim {
    height: var(--header-height-mobile);
}

.header {
    position: absolute;
    z-index: 100;
    /* top: 0; */
    width: 100vw;

    padding: var(--space-sm) var(--space-md);
    

    background-color: white;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    
}



.layout {
    display: inline-grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
}
.brand {
    display: block;
    height: 3rem;
}
.venue {
    display: grid;
    grid-template-columns: auto auto;
    gap: var(--space-sm);
    align-items: flex-start;
}
.ctas {
    display: flex;
    gap: .25rem;
    align-items: center;
    margin-right: var(--space-sm);
}


.ctaButton {
    text-decoration: none;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 5px 30px;
    background-color: var(--primary);
    font-size: 1rem;
    color: white;
    text-align: center;
    line-height: 1;
    transition: all .35s ease;
    border-radius: var(--default-radius);
}
@media(hover:hover){
    .ctaButton:hover {
        background-color: black;
    }
}



@media (max-width:599px) {
    .header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .brand {
        height: 3rem;
    }
    /* .venue {
        display: none;
    } */
    .ctaButton {
        display: none;
    }
    .desktopOnly {
        display: none;
    }
}

@media (min-width: 600px) {
    .header {
        position: absolute;
        padding: var(--space-xs) var(--space-md);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
    .header.fixed {
        position: fixed;
        top: 0;
        margin-top: 0;
    }

    .header,
    .shim {
        height: var(--header-height);
    }
    .brand {
        height: 4rem;
    }
    .mobileOnly {
        display: none;
    }
}

@media(max-width:900px) {
    .hideTabletP {
        display: none;
    }
}