:root {
    --tacf-dark: #01175A;
    --tacf-green: rgb(0, 195, 78);
    --tacf-margin: var(--space-md);

}

@media(min-width:900px) {
    :root {
        --tacf-margin: 12vw;
    }
}

.tacf-green {
    color: var(--tacf-green);
}