.buttonGrid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media(min-width:600px){
    .buttonGrid {
        flex-direction: row;
    }
}